<template>
  <b-row>
    <div class="col-md-12 col-lg-12 consumption-bar">
      <div class="iq-card iq-card-block iq-card-stretch">
        <div class="iq-card-body p-4">
          <div class="row">
            <div class="col-lg-12">
              <h3>
                <span v-if="service == liveOpensId">LiveOpens</span>
                <span v-else-if="service == liveLeadsId">LiveLeads</span>
              </h3>
            </div>
          </div>

          <div v-if="isAccountActive" class="row text-center p-4 mb-2 mx-auto">
            <b-col class="col-12 px-1 d-flex flex-column"
              style="justify-content: center; border-right: 1px solid #cbcbcb;" :md="6" :xl="service == liveOpensId ? '2' : '3'">
              <div class="text-center" style="width:100%;">
                <div v-if="loading" class="d-flex justify-content-between">
                  <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                </div>
                <div v-if="plan">
                  <h5><strong>Current Subscription:</strong></h5>
                  <h5 class="text-info mt-4">
                    <strong>
                      {{ plan.name }} - {{ plan.balance }}
                      <span v-if="service == liveOpensId">Events</span>
                      <span v-if="service == liveLeadsId">Records</span>
                    </strong>
                  </h5>
                </div>
                <h5 v-else class="text-danger"><strong>Not Active Subscription</strong></h5>
              </div>
            </b-col>

            <b-col class="col-12 d-flex mb-2" :md="6" :xl="service == liveOpensId ? '3' : '4'">
              <div class="d-flex d-flex flex-column text-left justify-content-end  col">
                <!-- Label -->
                <h5 class="consumption-bar-title col text-left">
                  <span v-if="service == liveOpensId" class="consumption-bar-title">Events Remaining:</span>
                  <span v-else-if="service == liveLeadsId" class="consumption-bar-title">Records Remaining:</span>
                </h5>

                <!-- Value -->
                <span class="col pl-1">
                  <span v-if="loading" class="justify-content-center">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                  </span>
                  <span v-else-if="!loading" class="consumption-bar-number badge badge-light my-1 ml-1 p-2">
                    {{ filterFormatNumber(remaining) }}
                  </span>

                </span>
              </div>
            </b-col>


            <b-col class="col-12 d-flex mb-2" :md="6" :xl="service == liveOpensId ? '3' : '4'">
              <div class="d-flex flex-column text-left justify-content-end  col">
                <!-- Label -->
                <h5 class="consumption-bar-title col text-left">
                  <span>Overconsumption Limit:</span>
                </h5>

                <!-- Value -->
                <span class="col pl-1">
                  <span v-if="loading" class="justify-content-center">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                  </span>
                  <span v-else-if="!loading" class="consumption-bar-number badge badge-light my-1 ml-1 p-2">
                    <span v-if="limit == -1"><small class="small">Unlimited</small></span>
                    <span v-else>{{ filterFormatNumber(limit) }}</span>
                  </span>

                  <small v-if="plan">

                    <b-button v-if="!freeAccount && service == liveOpensId && !plan.is_trial" v-b-modal.LOE-update-event-limit
                      @click="getCurrentBalanceLimit" class="bg-white border-0 text-primary"
                      style="padding-left: 7px;padding-right: 2px;">
                      <i class="fas fa-pen"></i>
                    </b-button>

                    <b-button v-if="!freeAccount && service == liveLeadsId && !plan.is_trial" v-b-modal.LL-update-event-limit
                      @click="getCurrentBalanceLimit" class="bg-white border-0 text-primary"
                      style="padding-left: 7px;padding-right: 2px;">
                      <i class="fas fa-pen"></i>
                    </b-button>
                  </small>
                </span>
              </div>
            </b-col>

            <b-col v-if="service == liveOpensId" class="col-12 d-flex mb-2" :md="6" :xl="service == liveOpensId ? '3' : '4'">
              <div class="d-flex flex-column text-left justify-content-end  col">
                <!-- Label -->
                <h5 class="consumption-bar-title col text-left">
                  <span>Contacts:</span>
                </h5>

                <!-- Value -->
                <span class="col pl-1">
                  <span v-if="loading" class="justify-content-center">
                    <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                  </span>
                  <span v-else-if="!loading && service == liveOpensId"
                    class="consumption-bar-number badge badge-light my-1 ml-1 p-2">
                    {{ filterFormatNumber(contacts) }}
                  </span>
                </span>
              </div>

            </b-col>
          </div>


          <div v-if="isAccountActive" class="row p-4">
            <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
              <b-progress :max="100" height="1rem" data-toggle="tooltip" v-b-tooltip.hover top
                :title="`${events} (${Number(percent).toFixed(2)}%)` + ` | ${remainingFormatNumber} Events Remaining`">
                <b-progress-bar :value="percent" :label="`${events} (${Number(percent).toFixed(2)}%)`"></b-progress-bar>
              </b-progress>
            </div>
          </div>

        </div>


        <!-- Warning Service Information -->
        <div v-if="subscriptionIsCancelOrFinish() && subscriptionUsed" class="text-primary surline mb-2 mx-4">
          <span class="pt-0">
              <span>Warning: Your subscription has been <span>{{ subscriptionStatusLabel(subscriptionUsed) }}</span> <span v-show="subscriptionUsed.ends_at">as of <strong>{{ subscriptionUsed.ends_at }}</strong></span>.</span>
          </span>
        </div>
        <div v-else-if="freeAccount" class="row p-4"> <!--- && percent > 80 ??  -->
          <div class="col-md-12" :class="{ 'text-primary': !freeAccount, 'text-warning': freeAccount }">

            <span v-if="freeAccount" class="row p-4 pt-0">
              <div class="col-md-12 surline">
                <span>You are in a trial account until <strong>{{ plan ? plan.next_billing_date ?? plan.subscription_end_date :
                  user.account.trial_ends_at
                }}</strong>.</span>
                <br>
                <span v-if="freeAccount || percent > 80">You can change your plan <router-link
                    :to="{ name: 'user.billing' }">here</router-link>.</span>
              </div>
            </span>

          </div>
        </div>

          <div v-if="!freeAccount && (currentPlan && currentPlan.subscription_end_date) && planHasExpired()" class="row p-4">
            <div class="col-md-12 text-warning">
              <span class="row p-4 pt-0">
                <div class="col-md-12 surline">
                <span>Your Plan has been closed since <strong>{{ currentPlan.subscription_end_date }}</strong>.</span>
                <br>
                <span>Go To Billing Page to Change Your Plan.</span>
                </div>
              </span>
            </div>
          </div>

        <div v-if="!isAccountActive" class="row p-4">
          <div class="col-md-12">
            <b-alert :show="true" variant="danger" class="bg-white">
              <div class="iq-alert-icon">
                <i class="ri-information-line"></i>
              </div>
              <div class="iq-alert-text">Your Account is Currently <b>Inactive</b>.</div>
            </b-alert>
          </div>
        </div>

        


      </div>
    </div>


    <b-modal v-if="!freeAccount" :id="modalLimit.title" ref="updateLimitModal" hide-footer
      title="Update your Overconsumption limit">

      <b-form @submit="onSubmit" class="p-4 pb-0">

        <b-form-group label-for="input-1" description="">
          <b-row align-h="between" class="px-3">
            <label for="input-1">Overconsumption limit :</label>

            <b-form-checkbox switch v-model="is_unlimited">Unlimited</b-form-checkbox>


          </b-row>

          <b-form-input v-if="!is_unlimited" type="number" placeholder="Overconsumption limit" v-model="form.limit"
            required></b-form-input>
          <b-form-input v-else readonly placeholder="unlimited"></b-form-input>
        </b-form-group>
        <hr>

        <b-row class="text-right px-3 mb-0 pb-0" style="justify-content:end;">

          <b-button variant="primary" type="submit" class="text-right"> Update <b-spinner small type="grow"
              v-show="modal_loader"></b-spinner> </b-button>

        </b-row>

      </b-form>
    </b-modal>



  </b-row>

  <!-- Info modal -->
</template>

<script>
import api from '@/api/RestClient'
import { mapGetters } from 'vuex'
import { helper } from '@/helpers'
import {SERVICES_BY_ID, SERVICES} from "@/constantes";
import moment from 'moment'


const FINISH_STATUS = -2;
const CANCEL_STATUS = -1;

export default {
  name: 'ServiceConsumptionBar',
  props: ['service', 'options'],
  mounted() {
    this.init();
    this.getConsumptionDetails();
    this.fetchSubscriptionAlreadyUsed();
  },
  data() {
    return {
      is_unlimited: false,
      form: {
        limit: 0
      },
      loading: false,
      modal_loader: false,
      events: 0,
      limit: 0,
      remaining: 0,
      contacts: 0,
      percent: 0,
      balance: 0,
      plan: null,
      progress: false,
      freeAccount: false,
      profile: null,
      isAccountActive: true,
      showEditOverconsumption: false,
      modalLimit: {
        title: this.service == 1 ? 'LOE-update-event-limit' : 'LL-update-event-limit'
      },
      serviceData: null,
      currentPlan: null,
      subscriptionUsed: null,
      liveOpensId: SERVICES.LIVE_OPENS.id,
      liveLeadsId: SERVICES.LIVE_LEADS.id
    }
  },
  methods: {
    init() {
      this.progress = true;
      this.loading = true;


      if (this.options) {
        const data = this.options;

        let serviceIsFree = this.service == this.liveOpensId
          ? this.options.plan.is_trial && !this.liveOpenersSubscribed
          : this.options.plan.is_trial && !this.liveLeadsSubscribed;

        this.events = this.options.events;
        this.limit = this.options.limit ? data.limit : 0;
        this.remaining = this.options.remaining;
        this.contacts = this.options.contacts;
        this.percent = this.options.percent;
        this.balance = this.options.plan && this.options.plan.balance ? this.options.plan.balance : 0;
        this.plan = this.options.plan;
        this.freeAccount = this.options.plan.is_trial  || serviceIsFree;
        this.serviceData = data;

      }

      this.progress = false;
      this.loading = false;
    },
    clientHasSubscriptionValid() {

      let serviceSubscription = typeof this.UserSubscriptions[this.service] != 'undefined' ? this.UserSubscriptions[this.service] : null
      let isValidSubscription = null;

      if(serviceSubscription && serviceSubscription.active) {
        let time = serviceSubscription.ends_at ? moment(serviceSubscription.ends_at) 
          : serviceSubscription && !serviceSubscription.ends_at ;
        
        if (time !== true && time > moment() || time === true) isValidSubscription = true;

      }

      return this.isClient && isValidSubscription
      return this.isClient && isValidSubscription && (this.limit !== -1 && (this.remaining + this.limit <= 0))
    },
    onSubmit(e) {

      this.modal_loader = true;
      e.preventDefault();
      if (this.freeAccount) return;
      if (this.is_unlimited) this.form.limit = -1;

      const data = {
        service: this.service,
        limit: this.form.limit
      };

      api.balances.updateLimit(data).then(response => {

        if (response.success) {

          const data = response.data;
          this.limit = data.limit;

          this.$bvToast.toast('Limit successfully updated', {
            title: 'Success',
            variant: 'success'
          });

          this.$refs.updateLimitModal.hide();
        }

        this.modal_loader = false;

      }).catch(error => {
        this.modal_loader = false;

        this.$bvToast.toast('failed to update the limit', {
          title: 'Error',
          variant: 'danger'
        });
      })


    },
    getCurrentBalanceLimit() {
      if (this.service == this.liveOpensId) {
        this.modalLimit.title = 'LOE-update-event-limit'
      } else if (this.service == this.liveLeadsId) {
        this.modalLimit.title = 'LL-update-event-limit'
      }
      this.is_unlimited = (this.limit == -1);

      this.form.limit = (this.is_unlimited) ? 0 : this.limit;
    },
    getConsumptionDetails() {
      api.stats
        .getConsumptionDataService(this.service)
        .then(response => {
          if (response.success) {
            const data = response.data;
            this.currentPlan = data.plan;
          }
        })
        .catch(function (error) {
          if (error.response) {
            // handle error
          }
        })
    },
    planHasExpired() {
      return this.currentPlan && (typeof this.currentPlan.subscription_end_date != 'undefined' && this.currentPlan.subscription_end_date) && (moment(this.currentPlan.subscription_end_date) <= moment());
    },
    subscriptionIsCancelOrFinish() {
      let isCancel = (!this.UserSubscriptions[this.service] || this.UserSubscriptions[this.service] == 'undefined') && (this.subscriptionUsed && this.subscriptionUsed.status && [CANCEL_STATUS, FINISH_STATUS].includes(this.subscriptionUsed.status));

      return isCancel;
    },
    fetchSubscriptionAlreadyUsed() {
      api.subscriptions.accountAlreadyUseService(this.user.account_id, this.service)
        .then((response) => {
          if(response.success && response.data) {
            this.subscriptionUsed = response.data;
          }
        })
    },
    subscriptionStatusLabel(subscription) {
      let label = '';

      if(subscription.status == CANCEL_STATUS) {
        label = 'Cancelled';
      }
      if(subscription.status == FINISH_STATUS) {
        label = 'Finished'
      }

      return label
    },
    filterFormatNumber(value) {
      return helper.formatNumber(value)
    }
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      isClient: 'Auth/isClient',
      liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
      liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
      UserSubscriptions: 'Auth/UserSubscriptions'
    }),
    profileFirstname() {
      if (!this.profile) {
        return ';)';
      }
      return this.profile?.first_name;
    },
    remainingFormatNumber() {
      return this.remaining.toLocaleString();
    }
  }
}
</script>

<style>
.consumption-bar-balance {
  font-size: 12px;
}

.account-status {
  padding-left: 10px;
  display: block;
}

.surline {
  background-color: rgba(255, 212, 0, 0.13) !important;
  padding: 5px 20px;
  color: rgb(185, 86, 16);
  border-radius: 6px;
}

div.remaining-alert h2.swal2-title {
    background-color: #f6386b !important;
    padding: 0.5rem !important;
    color: white;
}

@media (max-width: 991px) {
  .consumption-bar {
    font-size: 12px;
  }

  .consumption-bar-number {
    font-size: 21px;
    line-height: 1.5;
  }

  .consumption-bar-plan-used {
    width: 100%;
    border-right: 0px solid #cbcbcb;
  }

  .consumption-bar div.col-lg-3 {
    max-width: 50%;
  }

  .consumption-bar-title {
    line-height: 2 !important;
    display: block;
    width: 100%;
    white-space: nowrap;
  }
}
</style>
