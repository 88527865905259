<template>

    <b-container fluid>

        <b-row>

            <b-form-group class="col-md-12" label="Reception List: *" label-for="integration-tag">

                <multiselect v-model="tag" :loading="isLoading" tag-placeholder="Create List" :allow-empty="false"
                    deselect-label="Can't remove this value" placeholder="Select List" label="name" track-by="code"
                    return="code" :options="tagsFormatted" :multiple="false" :close-on-select="true" :taggable="true"
                    @tag="addTag" @select="updateValue" :disabled="isDisabled">

                    <template slot="singleLabel" slot-scope="props">
                        <div class="option__desc">

                            <span class="option__title">

                                <span v-if="props.option.type">
                                    <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                        style="color:#5ad"></i>
                                    <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>
                                </span>
                                <span v-else>
                                    <i class="ri-list-check color-primary" style="color:#5ad"></i>
                                </span>

                                <span style="font-wight:bold;">
                                    {{ props.option.name }}
                                </span>

                                <span style="font-size:11px; color:#888">
                                    {{ props.option.code }}
                                </span>

                            </span>

                        </div>
                    </template>

                    <template slot="option" slot-scope="props">
                        <div class="option__desc">

                            <span class="option__title">

                                <span v-if="props.option.type">

                                    <i v-if="props.option.type == 'segment'" class="ri-flashlight-line"
                                        style="color:#5ad"></i>
                                    <i v-else class="ri-list-check color-primary" style="color:#5ad"></i>

                                </span>

                                <span v-else><i class="ri-list-check color-primary" style="color:#5ad"></i></span>

                                <span style="font-wight:bold;"> {{ props.option.name }} </span>

                                <span style="font-size:11px; color:#888"> {{ props.option.code }}</span>


                            </span>

                        </div>

                    </template>

                </multiselect>

                <!-- <small v-if="errors?.tag" class="text-danger">Tag is required</small> -->
            </b-form-group>

        </b-row>



    </b-container>

</template>
<script>

import LimitPerDayInput from "@/components/inboxgeek/fields/integration/LimitPerDayInput";

import Multiselect from 'vue-multiselect'

import api from '@/api/RestClient';

export default {
    name: 'HubspotLoIntegrationStep',
    components: {
        LimitPerDayInput,
        Multiselect
    },
    props: {
        tags: {
            type: [Array, Object],
            default: () => []
        },
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            tag: null,
            isDisabled: false,
        }
    },
    methods: {
        handleUpdateTag(data) {
            this.$emit('tag', {tag: data, type: data.type ?? 'list'})
        },
        handleTagAdded(tag) {
            this.$emit('tagAdded', tag)
        },
        handleUpdateLimitPerDay(data) {
            this.$emit('limitPerDay', data)
        },
        updateValue(data) {
            this.$emit('tag', {tag: data})
        },
        addTag(tag) {
            
            this.isLoading = true;

            let _params = {};
            let platform = this.form.platform.platform.id;
            let platformName = this.form.platform.platform.name.toLowerCase();
            // let listId = this.form.list.list[0].code;
            let listId = (typeof this.form.list.list !== 'undefined' && this.form.list.list.length) ? this.form.list.list[0].code : null;

            _params.platform = platform;
            _params.platform_id = platform;
            _params.entities = 'tags';
            _params.tag = tag;
            _params.listId = listId;
            _params.platform_name = platformName;

            var title = 'Ooops ...';
            var message = 'An Something went wrong, please try later';
            var success = 'error';
            var tagLabel = 'Tag';

            api.platforms.addTag(_params)
                .then((response) => {
                    var results = 'data' in response.data ? response.data : response;
                    if (response.success) {

                        let tagData = {}

                        tagData = this.getTagAdded(results)

                        this.tag = tagData;
                        this.$emit('tagAdded', tagData);

                        title = 'Success!';
                        message = tagLabel + ' ' + this.tag.name + ' has created successfully';
                        success = 'success';
                        
                        this.updateValue();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        message = error.response.data.errors
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        message = error.message;
                    }
                })
                .finally(() => {
                    this.$swal(title, message, success)
                    this.isLoading = false;
                })

        },
    },
    computed: {
        tagsFormatted() {

            let tags = this.tags.map((tag) => {
              return {
                code: tag.listId,
                name: tag.name,
                type: tag.type ?? 'tag'
              }
            });

          return tags.map((tag)=>{

            console.log('tag',tag,this.form.list.list.some(item => item.code === tag.code));

            if(this.form.list.list.some(item => item.code === tag.code)){
              tag['$isDisabled'] = true;
            }
            return tag;
          });

        }
    }
}

</script>