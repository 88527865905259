<template>
<div>

    <b-row>

      <b-form-group class="col-md-12" label="Integration Name: *" label-for="integration-name">
        <b-form-input v-model="name" name="integration-name" placeholder="Enter Integration Name">
        </b-form-input>

        <small v-if="errors?.name" class="text-danger">Integration Name is required</small>
      </b-form-group>

      <component v-if="platform"
        v-bind:is="selectedPlatformComponent"
        v-bind="{tags: platformTags, form}"
        @tag="handleTag"
        @name="handleIntegrationName"
        @tagAdded="handleTagAdded"
        ></component>

    </b-row>

  <LoIntegrationOptionsWizardModal   :platform="PlatformHelper"
                                     @limitPerDay="handleLimitPerDay"
                                     @timeLimitApplied="handleTimeLimit"
                                     @recurrence="handleRecurrence"></LoIntegrationOptionsWizardModal>


  <br>
    <!-- Navigation buttons -->
    <NavigationLoWizard

        :isLoading = "false"
        :valid = "valid"
        @cancel="$emit('cancel')"
        @next="next"
        @back="back"

    ></NavigationLoWizard>

</div>
</template>

<script>
import { PLATFORMS_CODE } from "@/constantes";

import Multiselect from 'vue-multiselect'

import NavigationLoWizard from "@/components/inboxgeek/modals/Wizards/LoIntegration/NavigationLoWizard";

import ActiveCampaign from './integration/ActiveCampaignLoIntegrationStep';
import Aweber         from './integration/AweberLoIntegrationStep';
import Blastable      from './integration/BlastableLoIntegrationStep';
import Brevo          from './integration/BrevoLoIntegrationStep';
import Convertkit     from './integration/ConvertKitLoIntegrationStep';
import Campaigner     from './integration/CampaignerLoIntegrationStep';
import Klaviyo        from './integration/KlaviyoLoIntegrationStep';
import Maropost       from './integration/MaropostLoIntegrationStep';
import OmniSend       from './integration/OmniSendLoIntegrationStep';
import Listrak        from './integration/ListrakLoIntegrationStep';
import GoHighLevel    from './integration/GohighLevelLoIntegrationStep';
import Ontraport      from './integration/OntraportLoIntegrationStep';
import Sendlane       from './integration/SendlaneLoIntegrationStep';
import SendGrid       from './integration/SendGridLoIntegrationStep';
import MailSend       from './integration/MailSendLoIntegrationStep';
import GetResponse    from './integration/GetResponseLoIntegrationStep';
import Ongage         from './integration/OngageLoIntegrationStep';
import Beehiiv        from './integration/BeehiivLoIntegrationStep';
import EmailOctopus   from './integration/EmailOctopusLoIntegrationStep';
import Inbox          from './integration/InboxLoIntegrationStep';
import Hubspot        from './integration/HubspotLoIntegrationStep';
import Mailchimp      from './integration/MailchimpLoIntegrationStep';
import InfusionKeap   from './integration/InfusionKeapLoIntegrationStep';
import ExpertSender   from './integration/ExpertSenderLoIntegrationStep';
import Webhook from './integration/WebhookLoIntegrationStep'

import api from "@/api";
import LoIntegrationOptionsWizardModal
  from "@/components/inboxgeek/modals/Wizards/LoIntegration/LoIntegrationOptionsWizardModal";


export default {
    name: "IntegrationLoCreateIntegrationStep",
    props: ['account', 'PlatformHelper','form', 'tags'],
    components: {
      LoIntegrationOptionsWizardModal,
        Multiselect,
        NavigationLoWizard,
        ActiveCampaign,
        Aweber,
        Blastable,
        Brevo,
        ExpertSender,
        Convertkit,
        Campaigner,
        Klaviyo,
        Maropost,
        OmniSend,
        Listrak,
        GoHighLevel,
        Ontraport,
        Sendlane,
        SendGrid,
        MailSend,
        GetResponse,
        Ongage,
        Beehiiv,
        EmailOctopus,
        Inbox,
        Hubspot,
        Mailchimp,
        InfusionKeap,
      Webhook

    },
    mounted() {

        if (this.form.platform.platform) {
            this.platform = this.form.platform.platform
        }

        this.loadIntegrationData()

        this.handlePreloadTags();

    },
    data() {
        return {
            errors: {
                name: false,
                tag: false,
                limitPerDay: false,
                recurrence: false,
            },
            platform: null,
            platform_codes: PLATFORMS_CODE,
            platformTags: [],
            name: null,
            tag: null,
            limitPerDay: null,
            recurrence: null,
            timeLimit:{
              active:false,
              from:null,
              to:null
            },
            field: null,
            tagType: null
        }
    },
    methods: {

        next() {

            this.errors = {
                name: false,
                tag: false,
                limitPerDay: false,
                recurrence: false,
                timeLimit:{
                  from:false,
                  to:false
                }
            };

            if (!this.name) {
                this.errors.name = true;
                return;
            }

            let integration = {
                name: this.name,
                tag: this.tag,
                tagType: this.tagType,
                limit: this.limitPerDay ?? -1,
                recurrence: this.recurrence ?? 0,
            };

            if (this.field) {
                integration.field = this.field;
            }

            if(this.timeLimit.active){
              integration.time_limit = {
                time_limit_from: this.timeLimit.from,
                time_limit_to: this.timeLimit.to,
              };
            }

            if (integration.limit == -1) {

                let link = "https://help.inboxgeek.com/your-inboxgeek-account/#1679424669960-cc4ec555-bc63"
                this.$swal({
                    title: 'Are you sure?',
                    html: `<span>
                        You have not set a daily limit for your integration. Are you sure you want to continue? <br>
                        <small>**To learn more about setting your daily limit, click <a target="_blank" href="${link}">HERE</a></small>
                    <span>`,
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                    cancelButtonText: 'Go back',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        this.$emit('next', integration);
                    } 
                })
                return
            }
            this.$emit('next', integration);
        },
        back() {
            this.$emit('back', this.form);
        },
        handleTag(data) {
          console.log('handelTag',data);
            this.tag = data.tag;
            this.tagType = data.type;
        },
        handleTagAdded(tag) {
            this.$emit('tagAdded', tag);
        },
        handleIntegrationName(data) {
            this.name = data.name;
        },
        handleLimitPerDay(data) {
            this.limitPerDay = data.limit;
        },
      handleTimeLimit(data){
          this.timeLimit = data;
      },
        handleRecurrence(data) {
            this.recurrence = data.recurrence;
        },
        handleCustomFieldValue(data) {
            this.field = data.field ? data.field : null;
        },
        loadIntegrationData() {
            if (this.form.integration) {
                let integration = this.form.integration;

                this.name = integration.name;
                this.tag = integration.tag;
                this.limitPerDay = integration.limitPerDay;
                this.recurrence = integration.recurrence;
                this.tagType = integration.tagType;
            }
        },
        handlePreloadTags() {

            if (!this.platform) return;

            // this.loading = true;
            this.platformTags = [];

            let listIds = null;
            let list = this.form.list.list;

            if (typeof list == 'object' && list.length) {
                listIds = list.map(list => {
                    return list.code
                });
                listIds = listIds.join(',')
            } else {
                listIds = list.code ?? null;
            }

            let params = {
                entities: 'tags',
                platform_id: this.platform.id,
                platform_name: this.platform.name.toLowerCase(),
                list_id: listIds,
                list_ids:listIds
            };

            api.platforms.fetchServices(params)
                .then((response) => {

                    if (response.success) {
                        this.platformTags = response.data;
                    }

                    // this.loading = false;
                }).catch(error => {
                    console.log('error', error);
                    // this.loading = false;
                });
        },
    },
    computed: {
        valid() {

            return !(!this.name || !this.tag);

        },
        selectedPlatformComponent() {
            return this.platform && this.platform.name ? this.platform.name : null;
        }
    }

}
</script>

<style scoped>
.choice {
    cursor: pointer;
    border: .125rem dashed #ff739a !important;
}

.choice:hover {
    box-shadow: 0rem 0rem .1875rem #f6386bdb, .0625rem .0625rem .8125rem #f6386b69 !important;
    border: .125rem solid #ff739a !important;
}
</style>
