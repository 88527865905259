<template>

    <b-container fluid>
        <!-- Main table element -->
        <b-table class="ig-text-wrap" ref="table" :id="id" striped bordered :responsive="$store.getters['Auth/isAdmin']"
            :busy="isBusy" :items="getData" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" stacked="md" show-empty small @filtered="onFiltered"
            @sort-changed="sortingChanged">


            <!-- A custom formatted header cell for field 'selected' -->
            <template #head(selected)="row">
                <b-form-checkbox id="select-all" v-model="selectAll" name="selected" :indeterminate.sync="indeterminate"
                    @input="selectRow">
                </b-form-checkbox>
            </template>

            <template #table-busy>
                <div class="text-center d-inline-block my-2">
                    <span class='fa-stack fa-lg'>
                        <i class='fa fa-spinner fa-spin fa-stack-2x fa-fw'></i>
                    </span>&emsp;Processing ...
                </div>
            </template>

            <template #empty="scope">
                <p class="mb-1">No matching records found</p>
            </template>

            <!-- Cell selected -->
            <template v-slot:cell(selected)="row">
                <div class="text-center">
                    <input type="checkbox" v-model="row.item.selected" :checked="isChecked(row.item)"
                        @input="hasChangeRowSelect($event, row.item)" />
                </div>
            </template>

            <!-- Cell status -->
            <template #cell(status)="row">
                <span class="badge cursor-pointer badge-secondary badge-pill"
                    :class="row.item.active == 1 ? 'iq-bg-success' : 'iq-bg-danger'">
                    {{ row.item.active == 1 ? 'Active' : 'Inactive' }}
                </span>
            </template>

            <!-- Cell list_quality -->
            <template #cell(list_quality)="row">
                <span>
                    <span><i class="mr-1" :class="listQualityIndic(row.item)"></i>{{ listQuality(row.item) }}%</span>
                </span>
            </template>

            <!-- Cell remaining -->
            <template #cell(remaining)="row">
                <span :class="{ 'badge badge-light': row.value == 'None' }">
                    {{ row.value }}
                </span>
            </template>

            <!-- Cell oc_limit -->
            <template #cell(oc_limit)="row">
                <span :class="{ 'badge badge-light': row.value == 'None' }">
                    {{ row.value }}
                </span>
            </template>

            <!-- Cell actions -->
            <template #cell(actions)="row">
                <div class="text-center">
                    <button @click="getChart(row.item, row.index, $event.target)" type="button"
                        class="btn iq-bg-primary mr-1 btn-sm" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Test">
                        <i class="fa fa-eye" v-if="row.item.state != 101"></i>
                        <b-spinner small label="Small Spinner" v-if="row.item.state == 101" type="grow"></b-spinner>
                    </button>
                </div>
            </template>

        </b-table>

        <!-- Data table pagination -->
        <b-row class="justify-content-between mt-3">
            <b-col md="9" class="my-1">
                <div class="dataTables_info">
                    <b-row class="dataTables_info justify-content-between" id="lists_info" role="status" aria-live="polite">
                        <b-col md="10" class="">
                            Showing {{ from }}
                            to {{ to }} of {{ totalRows }} entries
                        </b-col>
                        <b-col md="2" class="">
                            <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm">
                            </b-form-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="3" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </b-container>

</template>
<script>

import { mapGetters } from 'vuex';
import api from '@/api/RestClient';
import { helper } from '@/helpers';


export default {
    name: 'AccountManagementTable',
    components: {},
    props: {
        tableId: {
            type: String,
            default: 'dt-live-reports'
        },
        accountId: {
            type: Number,
            default: null
        },
        filters: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters('Auth', ['user', 'isAdmin']),
    },
    data() {
        return {
            id: 'dt-live-reports',
            isBusy: false,
            items: [],
            fields: [
                {
                    key: 'selected',
                    label: '',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center'
                },
                {
                    key: 'account_id',
                    label: 'Organization',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center account',
                    formatter: (value, key, item) => {
                        return item.account ? item.account.name : '--'
                    }
                },
                {
                    key: 'referral_id',
                    label: 'Affiliate',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        return item.account?.referral?.name ?? '--'
                    }
                },
                {
                    key: 'name',
                    label: 'Integration name',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center integration',
                    formatter: (value, key, item) => {
                        return item.name
                    }
                },
                {
                    key: 'total_contacts',
                    label: 'Total Contacts',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const lists = item.lists;
                        const contacts = lists.length > 0 ? this.sumInArrayByKey(item.lists, 'total_contacts') : 0
                        return helper.formatDecimal(contacts);
                    }
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'limit',
                    label: 'Daily Limit',
                    sortable: true,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let limitPerDay = item.limit != -1 ? item.limit : '--';
                        return limitPerDay;
                    }
                },
                {
                    key: 'total_events',
                    label: 'Events for date range',
                    sortable: false,
                    sortDirection: 'desc',
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const totalEvents = item.events.length > 0 ? this.eventCalculStats(item.events) : 0;
                        return helper.formatDecimal(totalEvents);
                    }
                },
                {
                    key: 'avg_events',
                    label: 'Daily Events Average',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const events = item.events.length > 0 ? this.eventCalculStats(item.events) : 0;
                        return Math.round(events / this.reportDays);
                    }
                },
                {
                    key: 'billing_date',
                    label: 'Billing Date',
                    sortable: false,
                    class: 'text-center tag',
                    formatter: (value, key, item) => {
                        const size = item.account ? item.account.subscriptions.length : 0;
                        const nextBillingDate = size > 0 ? item.account.subscriptions[size - 1].next_billing_date : '--';
                        return nextBillingDate;
                    }
                },
                {
                    key: 'current_plan',
                    label: 'Current Plan',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        const size = item.account ? item.account.subscriptions.length : 0;
                        let currentPlan = size > 0 ? item.account.subscriptions[size - 1].plan.name : 'Free';
                        return currentPlan;
                    }
                },
                {
                    key: 'remaining',
                    label: 'Events balance Remaining',
                    sortable: true,
                    sortDirection: 'desc',
                    class: 'text-center account',
                    formatter: (value, key, item) => {
                        let balances = item.account ? item.account.balances : [];
                        const size = balances.length;
                        return size ? helper.formatDecimal(balances[0].balance) : 'None'
                    }
                },
                {
                    key: 'oc_limit',
                    label: 'Overconsumption Balance',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        let balances = item.account ? item.account.balances : [];
                        const size = balances.length;

                        let ov = size && balances[0].limit && item.limit == -1
                            ? helper.formatDecimal(balances[0].limit) : null;

                        return size && balances[0].limit
                            ? helper.formatDecimal(balances[0].limit) : 'None'
                    }
                },
                {
                    key: 'created_at',
                    label: 'Created At',
                    sortable: false,
                    class: 'text-center',
                    formatter: (value, key, item) => {
                        var dt = new Date(item.created_at);
                        return helper.formatDate(dt)
                    },
                },
                {
                    key: 'actions',
                    label: 'View graph',
                    sortable: false,
                    class: 'text-center'
                }
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100, {
                value: 100,
                text: "Show a lot"
            }],
            sortBy: 'active',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: ["name", "tag_name"],
            account: -1,

            from: null,
            to: null,
            dates: null,
            $search: '',
            reportDays: null,

            // filterData: null,
            selected: [],
            selectAll: false,
            indeterminate: false,
            links: [],

        }
    },
    mounted() {
        this.loadFields();

        // this.$root.$on('ibg:am-clear:selected', (item) => {
        //     this.selected = [];
        // });
    },
    methods: {
        loadFields() {
            let fields = _.map(this.fields, (item) => {
                if (this.isAdmin) {
                    return item;
                } else
                    if (![
                        "account_id",
                        "billing_date",
                        "events_cycle",
                        "current_plan",
                        "time_restrictions",
                        "created_by",
                        "monthly_events",
                        "list_quality"
                    ].includes(item.key)) return item;
            })
            this.fields = fields;
        },
        async getData(ctx) {
            await this.delay();
            return await this.fetchData(ctx);
        },
        async fetchData(ctx) {
            var items = []
            this.items = [];
            this.isBusy = true;
            let queryParams = {}
            this.$root.$emit('onFetchData');
            if (typeof ctx !== "undefined") {
                queryParams = {
                    search: this.filter,
                    page: ctx.currentPage,
                    per_page: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc,
                    filter_on: JSON.stringify(this.filterOn),
                    from: this.dates ? this.dates.from : -1,
                    to: this.dates ? this.dates.to : -1,
                    accountid: this.accountId && this.accountId != -1 ? this.accountId : this.account,
                    $search: this.search
                }

                if (this.filters) {
                    queryParams.filters = this.filters;
                }
            }

            return api.stats.accountReports(queryParams)
                .then((response) => {
                    items = response.data.reports.data;
                    const reportDays = response.data.days;
                    this.totalRows = response.data.reports.total
                    this.currentPage = response.data.reports.current_page
                    this.perPage = response.data.reports.per_page
                    this.items = items
                    this.reportDays = reportDays != 0 ? reportDays : 1;
                    this.from = response.data.reports.from
                    this.to = response.data.reports.to
                    this.links = response.data.reports.links;

                    return items
                })
                .catch((err) => {
                    console.log(err)
                    return []
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isBusy = false;
                        this.$emit('onFinishedFilter');
                        this.$root.$emit('updatedTable');
                    }, 400);
                })

        },
        delay: () => {
            return new Promise(resolve => setTimeout(resolve, 2000));
        },
        sortingChanged(ctx) {
            this.currentPage = 1
        },
        onFiltered(filteredItems) {
        },
        selectRow() {
            this.selected = []

            if (this.selectAll) {
                for (let i in this.items) {
                    this.selected.push(this.items[i].id);
                }
            } else {
                this.selected = []
            }
        },
        isChecked(item) {

            if (this.selected.includes(item.id)) {
                item.selected = true
            } else {
                item.selected = false
            }

            return 'checked' ? this.selected.includes(item.id) : '';
        },
        hasChangeRowSelect($event, item) {

            if (!this.selected.includes(item.id) && $event.target.checked) {
                this.selected.push(item.id)
            } else {
                this.selected = _.remove(this.selected, function (n) {
                    return n !== item.id
                });
            }

            if (this.selected.length) {
                this.indeterminate = true;
            } else {
                this.indeterminate = false;
            }

        },
        listQuality(item) {
            const contacts = this.sumInArrayByKey(item.lists, 'total_contacts');
            const events = this.eventCalculStats(item.cycle_events);
            const total = contacts == 0 ? 0 : events / contacts;
            return Math.round(total);
        },
        listQualityIndic(item) {
            const indicator = this.listQuality(item);
            if (indicator <= 50) return 'ri-arrow-down-fill text-danger';
            else if (50 < indicator < 70) return 'ri-arrow-down-fill text-warning'
            else return 'ri-arrow-up-fill text-success'
        },
        sumInArrayByKey(items = [], key = 'events_sent') {
            let sum = 0;
            items.forEach(item => {
                sum += item[key]
            });
            return sum;
        },
        eventCalculStats(array, key) {
            return this.sumInArrayByKey(array, key);
        },
        getChart(item, index, target) {
            // console.log('getChart', {item, index, target}); return;

            item.state = 101;
            this.$emit('getChart', {item, index, target});
        },
        clearSelection($event) {
            this.selectAll = false
            this.indeterminate = false;
            this.selected = []
        },
        displayReportSelected() {
            const that = this;
            let dataToDownload = [];
            let items = [];

            _.forEach(this.selected, function (itemID) {
                let item = _.find(that.items, function (o) { return itemID == o.id; });
                if (item) {
                    items.push(item);
                    const balances = item.account ? item.account.balances : [];
                    const size = balances.length;
                    dataToDownload.push({
                        name: item.name,
                        amount: size ? helper.formatDecimal(balances[0].balance) : 'Empty',
                        dailyLimit: item.limit != -1 ? item.limit : '--',
                        //! TODO Put graph image folder link
                        graph: '--',
                    });
                }
            });

            if (!dataToDownload.length) return

            this.$emit('onDisplayReport', { items: items, data: dataToDownload })

        },
        exportData(data, fileName = null, type = null) {
            excelParser().exportDataFromJSON(data, fileName, type);
        },
        async downloadSVG(chartId) {

            const chartInstance = window.Apex._chartInstances.find(
                (chart) => chart.id === chartId
            );
            const base64 = await chartInstance.chart.dataURI();
            const downloadLink = document.createElement("a");
            downloadLink.href = base64.imgURI;
            downloadLink.download = "image.png";

            // Add the anchor element to the document
            document.body.appendChild(downloadLink);

            // Simulate a click event to initiate the download
            downloadLink.click();

            // Remove the anchor element from the document
            document.body.removeChild(downloadLink);
        },
        selectAllItems($event) {
            let links = _.filter(this.links, function (o) { return o.url && !['Next &raquo;'].includes(o.label); });

            let queryParams = {
                search: this.filter,
                sort_by: this.sortBy,
                sort_desc: this.sortDesc,
                per_page: this.perPage,
                filter_on: JSON.stringify(this.filterOn),
                accountid: this.accountId && this.accountId != -1 ? this.accountId : this.account,
            }

            let allItemsPromises = _.map(links, (item) => {
                let page = item.url.split("?page=")[1]
                queryParams.page = page
                return api.stats.accountReports(queryParams)
            })


            const that = this

            Promise.all(allItemsPromises).then((values) => {

                _.forEach(values, function (resp) {
                    if (resp.success) {
                        _.forEach(resp.data.reports.data, function (row) {
                            let alreadyAdded = _.find(that.selected, function (o) { return row.id == o; });
                            if (!alreadyAdded) {
                                that.selected.push(row.id)
                            }

                            let alreadyAddedItems = _.find(that.items, function (o) { return row.id == o.id; });
                            if (!alreadyAddedItems) {
                                that.items.push(row)
                            }
                        });
                    }
                });

                that.selectAll = true;
                that.indeterminate = false;
            });
        }, 
    },
    watch: {
        selected: {
            handler(newValue, oldValue) {
                this.$emit('itemsSelected', {selected: this.selected, total: this.totalRows});
            }
        },
    }
}

</script>