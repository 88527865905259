<template>
  <div>
    <!-- START Subscriptions Card  -->
    <iq-card class="p-4">

      <template v-if="account.name" v-slot:headerTitle>
        <h4 class="card-title">Subscriptions</h4>
        <p>account : {{ account.name || '' }}</p>

      </template>


      <template v-if="account.name" v-slot:headerAction>
        <button class="btn btn-primary" @click="resetForm" v-b-modal.modal-add-new-subscription>Add Subscription</button>
      </template>

      <div class="p-4">

        <!-- START FILTER OPTIONS ROW  -->
        <b-row class="my-2" align-h="between">

          <!-- START Sow Option Input -->
          <b-col md="2">
            <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <!-- END Sow Option Input -->


          <!-- START Filter Input -->
          <b-col md="4">
            <b-form-group class="mb-0">
              <b-input-group>
                <b-form-input id="filter-input" v-model="filter" type="search"
                  placeholder="Type to Search"></b-form-input>

              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- END Filter Input -->

        </b-row>
        <!-- END FILTER OPTIONS ROW  -->


        <b-table borderless hover :items="subscriptions" :fields="fields" :current-page="currentPage" :per-page="perPage"
          :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :busy="loading.table" stacked="md" show-empty @filtered="onFiltered">

          <template #table-busy>
            <div class="text-center p-4 d-flex justify-content-center align-items-center">
              <b-spinner variant="primary"> </b-spinner>
              <span class="text-primary p-2"><small> Fetching subscriptions, Please wait...</small></span>
            </div>
          </template>

          <template #cell(plan)="row">
            {{ row.item.plan.name }}

          </template>

          <template #cell(service)="row">
            {{ row.item.plan.service.name }}

          </template>

          <template #cell(discount)="row">
            <div v-if="row.item.discount" class="badge"
              :class="{ 'badge-primary': row.item.discount.type == 'percentage', 'badge-info': row.item.discount.type != 'percentage' }">

              {{ row.item.discount.value }}

              {{ row.item.discount.type == 'percentage' ? '%' : '$' }}
              <span>
                <i class="ri-coupon-2-fill m-0"></i>
              </span>
            </div>
            <span v-else class="badge badge-light text-muted">none</span>

          </template>

          <template #cell(active)="row">
              <b-form-checkbox @change="updateSubscriptionStatus(row.item, $event)" switch :checked="![-2,-1, 0].includes(row.item.active) ? true : false">{{ row.item.active == 1 ? 'Active' : 'Inactive' }}</b-form-checkbox>
          </template>

          <template #cell(status)="row">
              <div class="badge" :class="subscription_status_class(row.item.status)">
                {{ subscription_status_text(row.item.status) }}
              </div>
          </template>

          <template #cell(actions)="row">

            <b-button variant=" iq-light  mr-1 mb-1" class="border shadow-sm" v-b-modal.subscription-option-modal  size="sm" @click="fetchSubscriptionOption(row.item)"><i class="ri-sound-module-line"></i></b-button>

            <b-button title="Apply Discount" data-toggle="tooltip" v-b-tooltip.hover top
              variant=" iq-bg-success mr-1 mb-1" size="sm" @click="applyDiscount(row.item)">
              <i class="ri-coupon-3-line m-0"></i>
            </b-button>
            <button type="button" class="btn iq-bg-primary mr-1 btn-sm" v-b-tooltip.hover data-toggle="tooltip" title="Histories" @click="histories(row.item)">
                <i class="fa fa-history m-0"></i>
            </button>
            <b-button title="Subscription Period" data-toggle="tooltip" v-b-tooltip.hover top
                variant=" iq-bg-primary mr-1 mb-1" size="sm" v-show="!row.item.plan.is_trial" @click="subscriptionPeriod(row.item)">
                <i class="ri-indent-decrease"></i>
              </b-button>
            <b-button variant=" iq-bg-info mr-1 mb-1" size="sm" @click="update(row.item)"><i
                class="ri-ball-pen-fill m-0"></i></b-button>
            <b-button variant=" iq-bg-danger  mr-1 mb-1" size="sm" @click="destroy(row.item)" v-if="![0,-1].includes(row.item.status)">
              <i class="ri-delete-bin-line m-0" v-if="![101].includes(row.item.status)"></i>
              <b-spinner small label="Small Spinner" type="grow" v-show="row.item.status == 101" ></b-spinner>
            </b-button>


          </template>


        </b-table>

        <!-- START PAGINATION TABLE-->
        <b-row align-h="end">

          <b-col md="3" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
          </b-col>
        </b-row>
        <!-- END PAGINATION TABLE-->
      </div>

    </iq-card>
    <!-- END Subscriptions Card  -->


    <!-- START MODAL Subscription  -->
    <b-row>
      <b-col lg="12" md="12">
        <b-modal id="modal-new-subscription" ref="subscription" hide-footer :title="modal_title">

          <b-form @submit="formSubmit" class="p-4">

            <!-- START Plans select -->
            <b-form-group id="input-group-4" label="Plan : " label-for="input-4" description="">
              <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.plan_id" :options="filteredPlans"
                value-field="id" text-field="name"></b-form-select>
            </b-form-group>
            <!-- END Plans select -->
            <b-form-group id="input-group-4" label="Status: " label-for="input-4" description="">
              <select class="form-control" v-model="formData.status">
                <option value="-2">Finished</option>
                <option value="-1">Cancelled</option>
                <option value="0">Init</option>
                <option value="1">Current</option>
                <option value="2">Next</option>
                <option value="">in process...</option>
              </select>
            </b-form-group>

            <b-form-group id="input-group-1" label="Interval:" label-for="input-1" description="">
                <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.interval" :options="intervals"
                    value-field="id" text-field="name"></b-form-select>
            </b-form-group>

            <b-form-group v-show="formData.status != -1 && formData.status != -2" id="input-group-1" label="Next Billing date:" label-for="input-1" description="" v-if="formData.plan_id">
                  <b-form-input id="input-date" v-model="formData.next_billing_date" type="date" min="today_date" placeholder="Next billing date"
                    ></b-form-input>
            </b-form-group>

            <b-form-group v-show="formData.status == -1 || formData.status == -2" id="input-group-1" label="End at:" label-for="input-1" description="" v-if="formData.plan_id">
                  <b-form-input id="input-date" v-model="formData.ends_at" type="date" min="today_date" placeholder="End at"
                    ></b-form-input>
            </b-form-group>
            <!-- START Discounts select -->
            <b-form-group id="input-group-4" label="Discounts : " label-for="input-4" description="">
              <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.discount_id" :options="discounts"
                value-field="id" text-field="name"></b-form-select>
            </b-form-group>
            <!-- END Plans select -->

            <div>
              <b-alert :show="error!=null" variant="danger" style="display:block;">
                <h6 class="alert-heading">Invalid Data:</h6>
                <hr>
                <p> {{ error }} </p>
              </b-alert>
            </div>

            <div class="text-right">
              <hr>
              <b-button variant="primary" class="text-right px-4" type="submit" :disabled="false"> Submit
                <b-spinner small type="grow" v-show="loading.modal"></b-spinner>
              </b-button>

            </div>

          </b-form>

        </b-modal>
      </b-col>
    </b-row>
    <!-- END MODAL Subscription  -->


    <!-- START MODAL Subscription  -->
    <b-row>
      <b-col lg="12" md="12">
        <b-modal id="modal-add-new-subscription" ref="subscription-modal-trial" hide-footer title="Add Subscription">

          <b-form  class="p-4" @submit="startSubscription">


            <b-form-group id="input-group-4" label="Service : " label-for="input-4" description="">
              <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.service_id" :options="services"
                value-field="id" text-field="name"></b-form-select>
            </b-form-group>
            <!-- START Plans select -->
            <b-form-group id="input-group-5" label="Plan : " label-for="input-4" description="" v-if="formData.service_id">
              <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.plan_id" :options="filteredPlans"
                value-field="id" text-field="name" ></b-form-select>
            </b-form-group>
            <!-- END Plans select -->
            <b-form-group>
              <b-form-checkbox v-if="false" v-model="formData.with_invoice" name="check-button" switch>
                Generate invoice?
              </b-form-checkbox>
            </b-form-group>
              <b-form-group id="input-group-1" label="Next Belling Date:" label-for="input-1" description="" v-if="formData.plan_id">
                <b-form-input id="input-1" v-model="formData.next_billing_date" type="date" placeholder="Next Belling Date"
                  :disabled="next_belling_date_disabled" :required="is_next_billing_required"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-1" label="Ends Date:" label-for="input-1" description="" v-if="formData.plan_id">
                <b-form-input id="input-1" v-model="formData.ends_at" type="date" placeholder="Ends Date"
                  ></b-form-input>
              </b-form-group>
              <!-- START Plans select -->
              <b-form-group id="input-group-4" label="Discount : " label-for="input-4" description="" v-if="formData.plan_id">
                <b-form-select style="background-color: #e9edf4;border: 0;" v-model="formData.discount_id" :options="discounts"
                  value-field="id" text-field="name"></b-form-select>
              </b-form-group>
              <!-- END Plans select -->

            <div>
              <b-alert :show="error!=null" variant="danger" style="display:block;">
                <h6 class="alert-heading">Invalid Data:</h6>
                <hr>
                <p> {{ error }} </p>
              </b-alert>
            </div>


            <div class="d-flex" style="justify-content: end;">
              <b-button variant="primary"  type="submit" :disabled="!formData.plan_id || loading.modal"> Start Subscription  <b-spinner small type="grow" v-show="loading.modal"></b-spinner></b-button>
            </div>

          </b-form>

        </b-modal>
      </b-col>
    </b-row>
    <!-- END MODAL Subscription  -->

    <!-- START MODAL Subscription  -->
    <b-row id="subPeriod">
      <b-col lg="12" md="12" v-show="show_subscription_periods">
        <SubscriptionPeriod :subscriptionsPeriods="subscription_periods" :plan="plan" ref="subs_period" :subscription_id="subscription_id" :account_id="account.id" @oneDoneSubsPeriod="doneSubsPeriod"/>
      </b-col>
    </b-row>
    <!-- END MODAL Subscription  -->

    <!-- START TIME LINE  -->
    <b-modal id="modal-time-line" ref="modal-timeLine" title="" :hide-footer=true>
      <TimeLine :subscriptions_logs="subscriptions_logs" :name="subscription_name" @onGetAll="all_subscriptions_logs"></TimeLine>
    </b-modal>
    <!-- END TIME LINE  -->
    <SubscriptionOptionModal :account="account" :subscription="selected_subscription"></SubscriptionOptionModal>
  </div>
</template>

<script>
import { sofbox } from '../../../config/pluginInit'
import Spinner1 from '../../../components/loaders/spinner1'
import TimeLine from '../children/timeLine'
import SubscriptionPeriod from './SubscriptionPeriods'
import api from '@/api/RestClient';
import SubscriptionOptionModal from './modals/SubscriptionOptionModal.vue'

var moment = require('moment');
const TRIAL_DAYS = 30;
export default {
  name: 'account-subscriptions',
  props: ['account'],
  components: {
    Spinner1,
    SubscriptionPeriod,
    SubscriptionOptionModal,
    TimeLine
  },
  data() {
    return {
      selected_subscription : null,
      plans: [],
      services: [],
      error:null,
      LiveLeadsIntervals: [
        {id: 'month', name: 'Month'},
        { id: 'year', name: 'Year' }
      ],
      LiveOpensIntervals: [
        {id: 'monthly', name: 'Monthly'},
        { id: 'yearly', name: 'Yearly' }
      ],
      plan: {},
      subscription_id: null,
      discounts: [],
      subscriptions: [],
      subscription_periods: [],
      today_date: moment().format("YYYY-MM-DD"),
      loading: {
        modal: false,
        table: false,
      },
      show_subscription_periods: false,
      modal_title: null,
      action: 'create',
      formData: {
        "service_id": 1,
        "plan_id": null,
        "interval": null,
        "next_billing_date": "",
        "active": false,
        "with_invoice": false,
        "ends_at": "",
        "discount_id": null
      },
      // Table data :
      fields: [
        { key: 'service', label: 'Service', sortable: true, sortDirection: 'desc' },
        { key: 'plan', label: 'Plan', sortable: true, class: 'text-center' },
        { key: 'interval', label: 'Interval', sortable: true, class: 'text-center' },
        { key: 'discount', label: 'Current Discount', sortable: false, class: 'text-center' },
        { key: 'next_billing_date', label: 'Next Billing Date', sortable: true, class: 'text-center' },
        { key: 'ends_at', label: 'End at', sortable: true, class: 'text-center' },
        { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
        { key: 'active', label: 'State', sortable: true, class: 'text-center'},
        { key: 'actions', label: 'Actions', class: 'text-center' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      next_belling_date_disabled: false,
      is_free_plan: false,
      subscription_name: '***',
      subscriptions_logs: [],
      subscription_active: 0
    }
  },
  async mounted() {

    sofbox.index();
    this.fetchServices();
    this.fetchPlans();
    this.fetchSubscriptions();
    this.fetchDiscounts();
    this.getInitialDiscount();

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'modal-add-new-subscription') {
        this.resetForm();
        this.getInitialDiscount();
      }
    })

  },
  methods: {
    getInitialDiscount() {
      let discounts = this.account.discounts;
      let discount = _.first(discounts);
      this.formData.discount_id = discount ? discount.id : null;
    },
    resetForm() {
      this.error=null;
      this.formData = {
        "service_id": 1,
        "plan_id": null,
        "interval": null,
        "next_billing_date": "",
        "active": false,
        "with_invoice": false,
        "ends_at": "",
        "discount_id": null,
        'status' : null
      }
    },
    fetchDiscounts() {
       api.discounts.list().then((response) => {

        let discounts = response.data.data;


        this.discounts = _.map(discounts, function (discount) {
           return {
            id: discount.id,
            name: discount.name
          }
        });
        this.discounts.push({id:null,name:'no discount'});
      }).catch(error => {
        console.log('failed to fetch discounts.')
      });
    },
    update(subscription) {

      this.action = 'update';
      this.modal_title = 'Edit Subscription';
      this.error=null;
      this.subscription_id = subscription.id;
      this.formData = {

        "account_id": this.account.id,
        "plan_id": subscription.plan_id,
        "interval": subscription.interval,
        "status" : subscription.status,
        "next_billing_date": subscription.next_billing_date ?? null,
        "active": subscription.active,
        "service_id": subscription.service_id,
        "ends_at": subscription.ends_at ? moment(subscription.ends_at).format('YYYY-MM-DD') : null,
        "discount_id": subscription.discount_id

      };
      this.$refs['subscription'].show();
    },
    destroy(subscription) {
      this.subscription_active = subscription.active;
      const state = 0;
      const isDelete = true;
      this.update_subscription(state, subscription, isDelete);
    },
    formSubmit(e) {

      e.preventDefault();

      if(!this.validate()){
        return;
      }

      const account_id = this.account.id;
      let data = this.formData;

      data.account_id = account_id;

      if (this.action == "create") {

        this.createSubscription(account_id, data);

      } else {
         this.loading.modal = true
        api.subscriptions.updateSubscription(this.subscription_id, data).then((response) => {
          let message = response.data.message;
          this.toast('Success', 'success', message);
          this.fetchSubscriptions();

          this.$refs['subscription'].hide();
          this.resetForm();
         }).catch((error) => {
           this.$bvToast.toast(error.response.data.message, {
            title: 'Error',
            variant: 'danger',
          });
        }).finally(() => {
          this.loading.modal = false
        })

      } // end if

    },
    createSubscription(account_id, data) {

      this.loading.modal = true;

      api.subscriptions.post(account_id, data).then(response => {

        this.loading.modal = false;

        let variant = 'danger';
        let message = 'Something went wrong'

        if ('success' in response) {
          variant = response.success ? 'success' : 'danger';
          message = response.message;
          this.toast('account', variant, message);
          if (response.success) {

            api.accounts.all().then(accounts => {

              this.accounts = accounts.data.data;
            });

            this.$refs['account'].hide();
          }
        }

        this.loading.modal = false;


      }).catch((error) => {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Error',
          variant: 'danger',
        });
      }).finally(() => {
        this.loading.modal = false;
      });
    },
    updateSubscription(account_id, data) {

      this.loading.modal = true;

      api.subscriptions.update(account_id, data).then(response => {

        let variant = 'danger';
        let message = 'Something went wrong'

        if ('success' in response) {
          variant = response.success ? 'success' : 'danger';
          message = response.message;
          this.toast('Account', variant, message);
          if (response.success) {
            this.fetchSubscriptions();
            this.$refs['account'].hide();
          }
        }
        this.action = 'create';
      }).catch((error) => {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Error',
          variant: 'danger',
        });
      }).finally(() => {
        this.loading.modal = false;
      });

    },
    toast(title = "success", variant = "success", message = "Organization updated successfully") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    fetchSubscriptions() {

      this.loading.table = true;

      api.subscriptions.list(this.account.id).then((response) => {
        this.subscriptions = response.data;
        this.totalRows = response.data.length;

      }).catch((error) => {

        this.subscriptions = [];
        this.$bvToast.toast(error.response.data.message, {
          title: 'Error',
          variant: 'danger',
        });
      }).finally(() => {
        this.loading.table = false;
      });

    },
    fetchPlans() {
      api.plans.list().then((response) => {
        this.plans = response.data.data;
      }).catch(error => {
        console.log('failed to fetch plans.')
      });
    },
    fetchServices() {
      api.services.list().then((response) => {
        this.services = response.data;
      }).catch(error => {
        console.log('failed to fetch service.')
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    applyDiscount(subscription) {
      let alert = {
        title: 'Apply Discount on Subscription',
        text: 'Error occurred, Please try later!'
      }

      const comp = this
      this.$swal.fire({
        title: 'Apply Discount',
        input: 'text',
        inputAttributes: {
          placeholder: 'Discount Code',
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Apply',
        showLoaderOnConfirm: true,
        preConfirm: (discount_code) => {
          return api.discounts.apply(discount_code, subscription.id)
            .then(response => {
              alert.text = response.message
            })
            .catch(error => {
              console.log(error)
              comp.$swal.showValidationMessage(
                error.response.data.message
              )
            })
        },
        allowOutsideClick: () => !comp.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          comp.$swal.fire(alert)
          comp.fetchSubscriptions()
        }
      })
    },
    startSubscription(e) {
      e.preventDefault();
      this.loading.modal = true;

      const account_id = this.account.id;

      if(!this.validate()){
        this.loading.modal = false;
        return;
      }

      api.subscriptions.subscribeSubscription(account_id,this.formData)
      .then((response) => {
        this.$refs['subscription-modal-trial'].hide();
        this.fetchSubscriptions();
      }).catch((error,response) => {
        this.$bvToast.toast(error.response.data.message, {
            title: 'Error',
            variant: 'danger',
          });

      }).finally(() => {
        this.loading.modal = false;
      })


    },
    updateSubscriptionPeriod(data) {
      console.log(data);
    },
    subscriptionPeriod(data) {
      this.$refs['subs_period'].setLoading(true);
      this.show_subscription_periods = true;
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#subPeriod").offset().top
      }, 800)
      this.subscription_id = data.id
      this.plan = data.plan;
      this.subscription_periods = _.map(data.subscription_period, function (o) {
          return {
            id: o.id,
            start_date: o.start_date,
            end_date:  o.end_date,
            status: o.status,
            invoice_id: o.invoice_id,
            consumption: o.consumption
        }
      });
    },
    doneSubsPeriod(data) {
      console.log(data);
      this.subscription_periods = data;
    },
    fetchSubscriptionOption(selected_subscription){
      this.selected_subscription = selected_subscription;
    },
    planSelected(code) {
      let plan = _.filter(this.plans, function (o) {
        if (o.id == code && o.is_trial) return o;
      });
      this.next_belling_date_disabled = false;
      this.formData.ends_at = null;
      this.is_free_plan = false;
      if (plan.length > 0) {//TODO this should be backend
        let end_date = moment(moment(), "YYYY-MM-DD").add(TRIAL_DAYS, 'days').format('YYYY-MM-DD');
        this.next_belling_date_disabled = true;
        this.formData.ends_at = end_date;
        this.is_free_plan = true;
      }
    },
    updateSubscriptionStatus(subscription, event) {
     this.update_subscription(event, subscription)
    },
    update_subscription(state, subscription, onDelete=false) {
      let status = state ? 1 : -1;
      let data = {
        active: status,
      }
      let message = 'Subscription status updated successfully.'
      if (onDelete) message = 'Subscription deleted successfully.'
      api.subscriptions.updateSubscription(subscription.id, data).then((response) => {
        this.toast('Success', 'success', message);
        subscription.active = status;
      }).catch(error => {
       this.$bvToast.toast(error.response.data.message, {
          title: 'Error',
          variant: 'danger',
       });
        subscription.active = this.subscription_active
      }).finally(() => {

      })
    },
    subscription_status_class(status) {
      let statusClass = ''
      switch (status) {
        case -2:
          statusClass = 'bg-danger'
          break;
        case -1:
          statusClass = 'bg-warning'
          break;
        case 0:
          statusClass = 'bg-primary'
          break;
        case 1:
          statusClass = 'bg-success'
          break;
        case 2:
          statusClass = 'bg-dark'
          break;
        default:
          statusClass = 'bg-default'
          break;
      }
      return statusClass != 'bg-default' ? statusClass + ' text-white' : statusClass;
    },
    subscription_status_text(status) {
      let statusText = ''
      switch (status) {
        case -2:
          statusText = 'Finished'
          break;
        case -1:
          statusText = 'Cancelled'
          break;
        case 0:
          statusText = 'Init'
          break;
        case 1:
          statusText = 'Current'
          break;
        case 2:
          statusText = 'Next'
          break;
        default:
          statusText = 'in process...'
          break;
      }
      return statusText;
    },
    histories(item) {
      this.subscription_name = `${item.plan.name}`;
      this.subscriptions_logs = item.logs;
      this.$refs['modal-timeLine'].show();
    },
    all_subscriptions_logs() {
      console.log('All logs');
    },
    validate(){


      this.error=null;

      if(this.formData.status == -2 || this.formData.status == -1){
        if(this.formData.ends_at == "" ){
          this.error = 'End at date must be defined for the finished and Cancelled status.';
          return false;
        }

        if(this.formData.next_billing_date != "" && this.formData.next_billing_date != null){
          this.error = 'next billing date should not be defined for the finished and Cancelled status.';
          return false;
        }

        //remove any previous next_billing_date value
        this.formData.next_billing_date = "";

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if(this.formData.ends_at){

          const ends_at = new Date(this.formData.ends_at );

          if(ends_at <= today){
            this.error = 'The ends_at date should be greater than or equal to today.';
            return false;
          }

        }




      }else{
        //remove any previous ends_at value
        this.formData.ends_at = "";

        if(this.formData.next_billing_date == "" ){
          this.error = 'next billing date should be defined.';
          return false;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if(this.formData.next_billing_date){

          console.log('validate , next_billing_date' ,this.formData.next_billing_date );
          const next_billing_date = new Date(this.formData.next_billing_date );
          today.setHours(0, 0, 0, 0);

          if(next_billing_date <= today){
            this.error = 'The next_billing_date should be greater than or equal to today.';
            return false;
          }

        }

      }


      return true;
    }
  },
  computed:{
    filteredPlans() {
      return this.plans
          .filter((plan) => ( plan.service_id === this.formData.service_id) && plan.active )
          .map(({ id, name }) => ({ id, name }));
    },
    is_next_billing_required()
    {
      return !this.formData.ends_at;

    },
    intervals()
    {
      if( this.formData.service_id === 2 ){
        return this.LiveLeadsIntervals;

      }
      return this.LiveOpensIntervals;
    }
  },
  watch: {
    account: function (newVal, oldVal) { // watch it
      this.fetchSubscriptions();
      this.resetForm();
      this.getInitialDiscount();
    }
  }

}
</script>


<style scoped>
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgb(171 216 225 / 15%);
}
</style>
