<template>
    <b-col class=" w-100 p-4 rounded" style="background: #c7e0f42b">
        <form action="#" @submit="submit" method="post" id="sendflow">

          <label for="validationDefaultUsername">Enter an email address below to test your integration.</label>
            <div class="row" id="email-inputs">
                <div class="col-9">

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend2">@</span>
                        </div>
                        <input id="testEmailFlow" type="email" class="form-control" v-model="email" placeholder="Email"
                            aria-describedby="inputGroupPrepend2" required>

                    </div>
                </div>

              <div class="col-3">
                <button class="btn btn-primary w-100" type="submit" style="padding: 10px" variant="primary" :disabled="send"><b-spinner small type="grow" v-if="send"></b-spinner>{{ sendText }}</button>
              </div>

            </div>

        </form>
    </b-col>
</template>
<script>
import api from '@/api/RestClient'
const BTN_TEXT = "Send Test Event"
export default {
    name: 'TestFlow',
    props: {
        integrationId: Number,
        col: String
    },
    mounted() {
        this.column = this.col;
    },
    data: () => ({
        column: 12,
        sendText: BTN_TEXT,
        send: false,
        email: ""
    }),
    methods: {
        submit(e) {
            e.preventDefault();
            this.send = true;
            this.sendText = 'Sending...';
            this.sendTestFlow(this.integrationId, this.email);
        },
        sendTestFlow(integrationId, email) {
            const that = this
            return api.integrations.sendTestFlow(integrationId, email)
                .then(result => {
                    that.sendText = BTN_TEXT;
                    let title = result.success ? 'Success!' : 'Oops ...';
                    let type = result.success ? 'success' : 'error';
                    that.$swal(title, 'Test event sent', type);
                })
                .catch(function (error) {
                    // handle error
                    if (error.response.data.errors) {
                        let title = 'Oops ...';
                        that.$swal(title, error.response.data.errors.message, "error");
                    }
                })
                .finally(() => {
                    that.send = false;
                    that.sendText = BTN_TEXT;
                });
        }
    }
}
</script>

<style>
.form-row {
    display: block !important;
}
</style>
