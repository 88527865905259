<template>

    <div>
        <h3 class="mb-4" style="margin-top: -20px;">Summary:</h3>
      <hr>
      <div class="row p-2 mx-0">
        <div class="d-flex w-100 justify-content-between">
          <div class="col-8">
            <h3 class="m-0"> Integration:  </h3>
            <p>{{ integration.name }} </p>
          </div>

          <div>

            <div class="row">

              <div class="col-2 px-1">
                <img class="float-right" style="width: 100%;" :src="getImageURL(platform.name)">
              </div>

              <div class="col-10">
                <h4>{{ platform.nickname }}</h4>
                <p><small>{{ platform.name }}</small></p>
              </div>

            </div>
          </div>

        </div>
      </div>

      <hr>

      <div class="px-3 mb-2">
        <h3>Lists: {{list.length ?? 0}}</h3>
      </div>

      <div style="overflow-y: hidden;overflow-x: scroll; width: 100%">
      <div class="d-flex p-2 px-4" style="gap:10px">

          <div class="p-2 border shadow-sm rounded px-3"  style="width:max-content; min-width: 350px" v-for="(item, index) in list" :key="index">
            <div class="row p-1">
              <div class="col" style="align-content: center;align-self: center;text-align: center;">
                <svg style="width: 30px;align-items: center;" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 5.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zM7 6a1 1 0 0 0 0 2h12a1 1 0 1 0 0-2H7zm0 5a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H7zm-1 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm-4-5a1.25 1.25 0 1 1 2.5 0A1.25 1.25 0 0 1 2 12zm1.25 3.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5z" fill="#55aadd"></path></g></svg>
              </div>

              <div class="col-10" style="align-content: center;">
                <h5 v-if="item.name">{{ item.name }}</h5>
                <p class="m-0" v-if="item.code"><small>{{ item.code }}</small></p>
              </div>
            </div>

          </div>

      </div>
      </div>
      <hr>
      <div class="row  mb-4 px-4">
                <div v-show="integration.tag.code || integration.tag.name" class="col">
                    <div class="d-flex" style="align-items: center">
                      <TriggerIconIntegrationWizardModal
                          :trigger="integration.tag"
                          style="width: 36px;margin: 5px 15px 5px -8px;"></TriggerIconIntegrationWizardModal>
                      <div>
                        <p class="m-0"> {{ ucFirst(integration.tagType) }}</p>
                        <h5>{{ integration.tag.name }} </h5>
                        <small class="badge badge-light"> {{ integration.tag.code }} </small>
                      </div>

                    </div>
                </div>

              <div class="col">
                <p class="m-0"> Limit per day:  </p>
                <h3> {{ integration.limit === -1 ? 'Unlimited' : integration.limit }} </h3>
              </div>

                <div class="col" v-if="PlatformHelper.hasRecurrence">

                    <p class="m-0">Recurrence:  </p>
                    <h5 :class="integration.recurrence ? '' : 'text-muted'">
                      {{ integration.recurrence ? integration.recurrence : 'disabled' }}
                    </h5>
                </div>

        <div class="col" v-if="form.integration.time_limit">

          <h5 class="m-0"><svg style="width: 25px;margin-right: 5px;margin-left: -5px;margin-bottom: 4px;" fill="#55aadd" viewBox="-0.96 -0.96 25.92 25.92" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" stroke="#55aadd" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M24,12a1,1,0,0,1-2,0A10.011,10.011,0,0,0,12,2a1,1,0,0,1,0-2A12.013,12.013,0,0,1,24,12Zm-8,1a1,1,0,0,0,0-2H13.723A2,2,0,0,0,13,10.277V7a1,1,0,0,0-2,0v3.277A1.994,1.994,0,1,0,13.723,13ZM1.827,6.784a1,1,0,1,0,1,1A1,1,0,0,0,1.827,6.784ZM2,12a1,1,0,1,0-1,1A1,1,0,0,0,2,12ZM12,22a1,1,0,1,0,1,1A1,1,0,0,0,12,22ZM4.221,3.207a1,1,0,1,0,1,1A1,1,0,0,0,4.221,3.207ZM7.779.841a1,1,0,1,0,1,1A1,1,0,0,0,7.779.841ZM1.827,15.216a1,1,0,1,0,1,1A1,1,0,0,0,1.827,15.216Zm2.394,3.577a1,1,0,1,0,1,1A1,1,0,0,0,4.221,18.793Zm3.558,2.366a1,1,0,1,0,1,1A1,1,0,0,0,7.779,21.159Zm14.394-5.943a1,1,0,1,0,1,1A1,1,0,0,0,22.173,15.216Zm-2.394,3.577a1,1,0,1,0,1,1A1,1,0,0,0,19.779,18.793Zm-3.558,2.366a1,1,0,1,0,1,1A1,1,0,0,0,16.221,21.159Z"></path></g></svg> Time Limit:  </h5>

          <p style="color: black !important;">
            From <strong>{{ form.integration.time_limit.time_limit_from }}</strong> to  <strong>{{ form.integration.time_limit.time_limit_to}}</strong>
          </p>
        </div>

      </div>

      <div v-if="itemCreated">

        <AlertIntegrationCreatedWizardModal :integration="itemCreated" @showTestFlow="updateShowTestFlow" />

      </div>

      <!-- Navigation buttons -->
        <hr>
        <div class="d-flex justify-content-end" style="gap:5px">
            <button class="btn btn-light" @click="$emit('cancel')">{{itemCreated ? 'Done' : 'Cancel'}}</button>
            <button class="btn btn-secondary d-flex align-items-center" v-if="!itemCreated" :disabled="itemCreated" @click="back">
                <i class="ri-arrow-left-line"></i>
                Back
            </button>
            <button class="btn btn-success d-flex align-items-center " v-if="!itemCreated" :disabled="!valid || itemCreated"
                @click="finish">
                <b-spinner v-show="isLoading" small type="grow" class="mr-2"></b-spinner>
                Create <i class="ri-save-2-line ml-2"></i>
            </button>
        </div>
    </div>


</template>
  <script>
import { PLATFORMS_ITEMS } from "@/constantes";
import { helper } from '@/helpers'

import AlertIntegrationCreatedWizardModal from '../AlertIntegrationCreatedWizardModal'
import TriggerIconIntegrationWizardModal from '../elements/TriggerIconIntegrationWizardModal'
  export default {
    name: "FinishLoCreateIntegrationStep",
    props: ['account', 'PlatformHelper','form', 'tags'],
    components: {
        AlertIntegrationCreatedWizardModal,
      TriggerIconIntegrationWizardModal
    },
    mounted() {

        // Platform
        if(this.form.platform && typeof this.form.platform.platform !== 'undefined'){
            this.platform = this.form.platform.platform;
        }

        // List
        if(this.form.list && typeof this.form.list !== 'undefined'){
            this.list = this.form.list.list;
        }
       // Integration
       if(this.form.integration && typeof this.form.integration !== 'undefined'){
            this.integration = this.form.integration;
        }


        this.$root.$on('integration-created', (integration) => {
            this.isLoading = false;
            this.itemCreated = integration;
        });
    },
    data(){
      return {
        isLoading: false,
        platform: null,
        list: null,
        integration: null,
        showTestFlow: false,
        itemCreated: null
      }
    },
    methods:{
        finish() {
            this.isLoading = true;

            this.$emit('finish');
        },
        back() {
            this.$emit('back', this.form);
        },
        getImageURL(platform) {
            try {
                return require(`@/assets/images/platforms/${platform.toLowerCase()}.png`)
            } catch (e) {
                return require(`@/assets/images/platforms/default.png`)
            }
        },
        updateShowTestFlow(data) {
            this.showTestFlow = data;
        },
        ucFirst(str) {
            return helper.capitalizeFirstLetter(str);
        }
    },
    computed: {
        valid() {
            // TODO add valid condition to create integration
            // Check all require form inputs
            // return !(!this.name);
            return true
        },
    }
  }
  </script>

  <style scoped>

  </style>
